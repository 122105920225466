import Image from "next/image";

import { InView } from "react-intersection-observer";

import { useTranslation } from "react-i18next";

import { Swiper, SwiperSlide } from "swiper/react";

import SwiperCore, {
  Keyboard,
  Pagination,
  Scrollbar,
  Navigation,
} from "swiper";

import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";

import PATHS from "../../../configs/paths";
import { clientAppFeatures } from "../../../configs/data";

import Title from "../../../components/Title";

import classNames from "./index.module.scss";

SwiperCore.use([Keyboard, Pagination, Scrollbar, Navigation]);

/**
 * Describes the capabilities of the app
 * features list is in the config/data.js.
 *
 * @returns AppFeatures
 */
export default function AppFeatures({ onChangeInView }) {
  const { t } = useTranslation();

  return (
    <InView
      as="div"
      threshold={0.1}
      onChange={(inView) => onChangeInView(inView, PATHS.FEATURES)}
    >
      <section id="app-features">
        <div className={classNames.page}>
          <div className={classNames.container}>
            <div className="title-subtitle">
              <Title>{t("app_features")}</Title>
              <p className="subtitle">{t("app_features_title")}</p>
            </div>
            <div className={classNames.slider}>
              <Swiper
                direction={"vertical"}
                slidesPerView={1}
                loop="true"
                keyboard={{
                  enabled: true,
                }}
                scrollbar={true}
                navigation={true}
                modules={[Keyboard, Scrollbar, Navigation]}
                className="mySwiper app-features"
              >
                {clientAppFeatures.map((el) => (
                  <SwiperSlide key={el.id}>
                    <div className="app-features-wrap">
                      <div className="desc">
                        <h2 className="subtitle">{t(el.title)}</h2>
                        <p className="text-wrap">{t(el.description)}</p>
                      </div>
                      <div className="wrap-img">
                        <Image
                          src={el.icon.src}
                          alt={el.title}
                          width={420}
                          height={190}
                        />
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </section>
    </InView>
  );
}
