import { useRouter } from "next/router";

import Link from "next/link";

import { useState } from "react";

import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";

import { List, Form, Input, Divider, Button } from "antd";

import { InView } from "react-intersection-observer";

import URL from "../../../configs/url";
import PATHS from "../../../configs/paths";
import { TELEGRAM } from "../../../configs/config";
import ICONS from "../../../configs/icons";

import Title from "../../../components/Title";
import ErrorModal from "../../../components/ErrorModal";
import ButtonComponent from "../../../components/ButtonComponent";

import styles from "./index.module.scss";

/**
 * Section is for admin to connect, input the email and message, showed success or error messages when we have a request message.
 * @returns Wrapper
 */

export default function ContactUs({ onChangeInView }) {
    const router = useRouter();
    const { t } = useTranslation();

    const { isLightMode } = useSelector((state) => state.appSlices);

    const [loading, setLoading] = useState(false);

    const [errorMessage, setErrorMessage] = useState(null);

    const [form] = Form.useForm();

    function capitalizeWords(input) {
        let withoutQuotes = input.replace(/"/g, "");
        let capitalizedText =
            withoutQuotes.charAt(0).toUpperCase() + withoutQuotes.slice(1).toLowerCase();
        return capitalizedText;
    }

    const sendThankyou = async (body) => {
        try {
            await fetch(URL.CONTACT_US_THKY, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(body),
            })
                .then(async (res) => {})
                .then((res) => {})
                .catch((error) => {});
        } catch (err) {}
    };

    const send = async (body) => {
        try {
            await fetch(URL.SEND_MESSAGE, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(body),
            })
                .then(async (res) => {
                    if (!res.ok) {
                        const error = await res.json();
                        throw error;
                    }
                    return res.json();
                })
                .then((res) => {
                    setLoading(false);
                    sendThankyou(body);
                    form.resetFields();
                    window.location.href = router.locale
                        ? `${window.location.origin}/${router.locale}/contact-us/thankyou`
                        : `${window.location.origin}/contact-us/thankyou`;
                })
                .catch((error) => {
                    if (typeof error === "string") {
                        setErrorMessage(capitalizeWords(error));
                    }

                    if (typeof error.message === "string") {
                        setErrorMessage(capitalizeWords(error.message));
                    }

                    setLoading(false);
                });
        } catch (err) {}
    };

    const onFinish = (values) => {
        setLoading(true);
        send(values);
    };

    const dataQuestions = [
        {
            title: t("who_can_use_our_product"),
            desc: <p>{t("questions_first")}</p>,
        },
        {
            title: t("how_can_i_register_as_a_reseller"),
            desc: (
                <p>
                    {t("you_have_to_navigate_to")}
                    <Link href="/register" style={{ color: "#91f278" }}>
                        {" "}
                        {t("registration_page")}
                    </Link>{" "}
                    {t("questions_second")}
                </p>
            ),
        },
        {
            title: t("questions_tree"),
            desc: <p>{t("of_course")}</p>,
        },
        {
            title: t("how_can_i_fix_my"),
            desc: (
                <p>
                    {t("questions_four")}{" "}
                    <a
                        href="mailto:xcloudapps@gmail.com"
                        target={"_blank"}
                        style={{ color: "#91f278" }}
                    >
                        xcloudapps@gmail.com
                    </a>{" "}
                </p>
            ),
        },
    ];

    return (
        <InView
            as="div"
            threshold={0.5}
            onChange={(inView) => onChangeInView(inView, PATHS.CONTACT)}
        >
            <section id="contact-us">
                <div className={styles.wrap}>
                    <div className={styles.page}>
                        <Title style={{ textAlign: "center" }}>{t("contact_us")}</Title>

                        <div className={styles.container}>
                            <div className={styles.questions_block}>
                                <p
                                    className={styles.title}
                                    style={{ color: isLightMode ? "#000" : "#005364" }}
                                >
                                    {t("frequently_asked_questions")}
                                </p>

                                <List
                                    itemLayout="horizontal"
                                    dataSource={dataQuestions}
                                    renderItem={(item, index) => (
                                        <List.Item>
                                            <List.Item.Meta
                                                title={item.title}
                                                description={item.desc}
                                            />
                                        </List.Item>
                                    )}
                                />
                            </div>

                            <div className={styles.form_block}>
                                <p
                                    className={styles.title}
                                    style={{ color: isLightMode ? "#000" : "#005364" }}
                                >
                                    {t("didnt_find_answers_to_your_questions")}
                                </p>
                                <Form
                                    form={form}
                                    onFinish={onFinish}
                                    style={{ width: "90%" }}
                                    className={styles["questions-form"]}
                                >
                                    <Form.Item
                                        name="name"
                                        rules={[
                                            {
                                                required: true,
                                                message: t("please_input_your_name"),
                                            },
                                        ]}
                                    >
                                        <Input placeholder={t("name")} />
                                    </Form.Item>

                                    <Form.Item
                                        name="email"
                                        rules={[
                                            {
                                                required: true,
                                                message: t("please_input_your_email"),
                                            },

                                            {
                                                type: "email",
                                                message: t("please_input_valid_email"),
                                            },
                                        ]}
                                    >
                                        <Input placeholder={t("email")} />
                                    </Form.Item>
                                    <Form.Item
                                        name="whatsapp_or_telegram"
                                        rules={[
                                            {
                                                required: true,
                                                message: t(
                                                    "please_input_your_whatsapp_or_telegram"
                                                ),
                                            },
                                        ]}
                                    >
                                        <Input placeholder={t("whatsapp_or_telegram")} />
                                    </Form.Item>

                                    <Form.Item
                                        name="message"
                                        rules={[
                                            {
                                                required: true,
                                                message: t("please_input_your_message"),
                                            },
                                        ]}
                                    >
                                        <Input.TextArea placeholder={t("message")} rows={5} />
                                    </Form.Item>
                                    <Form.Item
                                        name="heard_about_us"
                                        rules={[
                                            {
                                                required: false,
                                            },
                                        ]}
                                    >
                                        <Input.TextArea
                                            placeholder={t("how_have_you_heard_about_us")}
                                            rows={3}
                                        />
                                    </Form.Item>

                                    <div
                                        style={{ textAlign: "center", padding: "20px 0px" }}
                                        className="button-scale"
                                    >
                                        <ButtonComponent
                                            title={t("send")}
                                            loading={loading}
                                            onClick={() => form.submit()}
                                        />
                                    </div>
                                </Form>

                                <Divider>OR</Divider>
                                <Link href={TELEGRAM} target="_blank">
                                    <Button
                                        icon={ICONS.TELEGRAM}
                                        className="btn-become-partner"
                                        style={{ marginBottom: "20px" }}
                                    >
                                        {t("contact_on_telegram")}
                                    </Button>
                                </Link>
                            </div>
                        </div>

                        <ErrorModal
                            text={errorMessage}
                            visible={errorMessage ? true : false}
                            onClose={() => setErrorMessage(null)}
                        />
                    </div>
                </div>
            </section>
        </InView>
    );
}
