import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";

import React, { useState } from "react";

import { Modal, Form, Input } from "antd";

import URL from "../../../configs/url";

import ButtonComponent from "../../../components/ButtonComponent";
import ErrorModal from "../../../components/ErrorModal";

import styles from "./contactUs.module.scss";

export default function ContactUsModal({ open, onclose }) {
    const router = useRouter();

    const { t } = useTranslation();
    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);

    const [errorMessage, setErrorMessage] = useState(null);

    function capitalizeWords(input) {
        let withoutQuotes = input.replace(/"/g, "");
        let capitalizedText =
            withoutQuotes.charAt(0).toUpperCase() + withoutQuotes.slice(1).toLowerCase();
        return capitalizedText;
    }
    const thankYouSend = async (body) => {
        try {
            await fetch(URL.APP_DEVELOPMENT, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(body),
            })
                .then(async (res) => {})
                .then((res) => {})
                .catch((error) => {});
        } catch (err) {}
    };

    const send = async (body) => {
        try {
            await fetch(URL.CUSTOM_APP, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(body),
            })
                .then(async (res) => {
                    if (!res.ok) {
                        const error = await res.json();
                        throw error;
                    }

                    return res.json();
                })
                .then((res) => {
                    setLoading(false);
                    thankYouSend(body);
                    form.resetFields();
                    window.location.href = router.locale
                        ? `${window.location.origin}/${router.locale}/appdevelopment/thankyou`
                        : `${window.location.origin}/appdevelopment/thankyou`;
                    onclose();
                })
                .catch((error) => {
                    if (typeof error === "string") {
                        setErrorMessage(capitalizeWords(error));
                    }

                    if (typeof error.message === "string") {
                        setErrorMessage(capitalizeWords(error.message));
                    }
                    setLoading(false);
                });
        } catch (err) {}
    };

    const onFinish = (values) => {
        setLoading(true);
        send(values);
    };

    return (
        <Modal width={600} title={t("contact_us")} open={open} onCancel={onclose} footer={null}>
            <div className={styles.form_block}>
                <Form
                    form={form}
                    onFinish={onFinish}
                    style={{ width: "90%" }}
                    className="want-more-form"
                >
                    <Form.Item
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: t("please_input_your_name"),
                            },
                        ]}
                    >
                        <Input placeholder={t("name")} />
                    </Form.Item>

                    <Form.Item
                        name="surname"
                        rules={[
                            {
                                required: true,
                                message: t("please_input_your_surname"),
                            },
                        ]}
                    >
                        <Input placeholder={t("surname")} />
                    </Form.Item>

                    <Form.Item
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: t("please_input_your_email"),
                            },

                            {
                                type: "email",
                                message: t("please_input_valid_email"),
                            },
                        ]}
                    >
                        <Input placeholder={t("email")} />
                    </Form.Item>
                    <Form.Item
                        name="whatsapp_or_telegram"
                        rules={[
                            {
                                required: true,
                                message: t("please_input_your_whatsapp_or_telegram"),
                            },
                        ]}
                    >
                        <Input placeholder={t("whatsapp_or_telegram")} />
                    </Form.Item>

                    <Form.Item
                        name="message"
                        rules={[
                            {
                                required: true,
                                message: t("please_input_your_message"),
                            },
                        ]}
                    >
                        <Input.TextArea placeholder={t("message")} rows={5} />
                    </Form.Item>
                    <Form.Item
                        name="heard_about_us"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Input.TextArea placeholder={t("how_have_you_heard_about_us")} rows={3} />
                    </Form.Item>

                    <div className={`${styles.btn} button-scale`}>
                        <ButtonComponent
                            type="appColor"
                            title={t("send")}
                            loading={loading}
                            onClick={() => form.submit()}
                        />
                    </div>
                </Form>
            </div>
            <ErrorModal
                text={errorMessage}
                visible={errorMessage ? true : false}
                onClose={() => setErrorMessage(null)}
            />
        </Modal>
    );
}
