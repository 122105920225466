import Image from "next/image";
import { InView } from "react-intersection-observer";
import { useRouter } from "next/router";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Button } from "antd";

import ICONS from "../../../configs/icons";
import PATHS from "../../../configs/paths";

import Title from "../../../components/Title";

import PricingCard from "./PricingCard";
import styles from "./index.module.scss";
import IMAGES from "../../../configs/images";

export default function Pricing({ packages, onChangeInView }) {
  const router = useRouter();

  const { t } = useTranslation();
  const { isLightMode } = useSelector((state) => state.appSlices);

  const goBecomePartner = () => {
    router.push(PATHS.BECOME_PARTNER);
  };
  
  return (
    <InView
      as="div"
      threshold={0.2}
      onChange={(inView) => onChangeInView(inView, PATHS.PRICING)}
    > 
      <section id="pricing">
        <div className={styles.page}>
          <div className={styles.container}>
            <div className="title-subtitle">
              <Title>{t("pricing")}</Title>
              <p className="subtitle">
                {t("pricing_title")}
              </p>
            </div>
            <div className={styles.list}>
              <PricingCard data={packages} />
              <div
                className={styles["start-now"]}
                style={{
                  background: isLightMode ? `linear-gradient(45deg, #fff, #00000012)` : `linear-gradient(45deg, #173E4B, #00000012)`,
                }}
                onClick={goBecomePartner}
              >
                <h3 style={{color: isLightMode ? "#1f2226" : "#fff"}}>{t("become_a_partner")}</h3>
                <div>{ICONS.ADD_PARTNER}</div>
                <Button>{t("start_now")}</Button>
                <Image src={ isLightMode ? IMAGES.PRICE_SUBTRACT.src : IMAGES.PRICE_SUBTRACT_DARK.src} alt="become a partner" width={73} height={73}/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </InView>
  );
}
