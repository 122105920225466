import Image from "next/image";
import { useRouter } from "next/router";
import { InView } from "react-intersection-observer";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import ButtonComponent from "../../../components/ButtonComponent";

import PATHS from "../../../configs/paths";
import IMAGES from "../../../configs/images";

import styles from "./index.module.scss";

export default function Home({ onChangeInView }) {
  const router = useRouter();

  const { t } = useTranslation();
  const { isLightMode } = useSelector((state) => state.appSlices);

  const onFreeTrail = () => {
    router.push(PATHS.REGISTER);
  };

  return (
    <InView
      as="div"
      threshold={0.5}
      onChange={(inView) => onChangeInView(inView, PATHS.HOME)}
      initialInView={true}
    >
      <section id="home">
        <div className={styles.page}>
          <Image
            alt="cross platform"
            src={isLightMode ? IMAGES.HOME_BG_LIGHT.src : IMAGES.HOME_BG.src}
            quality={100}
            fill
            objectFit="cover"
            priority={true}
            sizes="100vw"
          />

          <article>
            <div className={styles.content}>
              <div>
                <h1 className="title">
                  <strong>{t("home_title_first")}</strong>
                </h1>
                <h2 style={{ color: "#3EFF0E" }}>
                  <strong>{t("home_title_second")}</strong>
                </h2>
                <h2 style={{ color: isLightMode ? "#808080" : "#fff" }}>
                  <strong>{t("home_title_tree")}</strong>
                </h2>
              </div>
              <div
                className={styles["sub-title"]}
                style={{ color: isLightMode ? "#808080" : "#fff" }}
              >
                <span>{t("home_subtitle")}</span>
                <br />
                <span style={{ fontSize: "13px" }}>{t("home_subtitle_2")}</span>
              </div>
              <div className={styles["btn-free-trial"]}>
                <ButtonComponent
                  type="gradient"
                  title={t("free_trial")}
                  onClick={onFreeTrail}
                />
              </div>
              <span
                className="no-credit-card-required"
                style={{ color: isLightMode ? "#000" : "#fff" }}
              >
                *{t("no_credit_card_required")}
              </span>
            </div>
            <div className={styles["home-img"]}>
              <Image
                src={isLightMode ? IMAGES.HOME_LIGHT.src : IMAGES.HOME_DARK.src}
                alt="home"
                width={420}
                height={220}
                loading="lazy"
              />
            </div>
          </article>
        </div>
      </section>
    </InView>
  );
}
