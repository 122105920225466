import { InView } from "react-intersection-observer";

import { useTranslation } from "react-i18next";

import PATHS from "../../../configs/paths";
import { features } from "../../../configs/data";

import Title from "../../../components/Title";
import Feature from "../../../components/Feature";

import styles from "./index.module.scss";

/**
 * Describes the capabilities of the provider's clients
 * features list is in the config/data.js.
 *
 * @returns FeaturesWrapper
 */

export default function Features({ onChangeInView }) {
  const { t } = useTranslation();

  return (
    <InView
      as="div"
      threshold={0.1}
      onChange={(inView) => onChangeInView(inView, PATHS.FEATURES)}
    >
      <section id="features">
        <div className={styles.page}>
          <div className={styles.container}>
            <div className="title-subtitle">
              <Title>{t("xcloud_iptv_and_media_player_features")}</Title>
              <p className="subtitle">{t("features_title")}</p>
            </div>

            <div className={styles.list}>
              {features?.map((item) => {
                return (
                  <Feature
                    key={item.id}
                    image={item.icon}
                    title={t(item.title)}
                    description={t(item.description)}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </InView>
  );
}
