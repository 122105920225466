import { Button } from "antd";
import styles from "../styles/button.module.scss";

export default function ButtonComponent({ type, title, loading, style, onClick, disabled, icon}) {
    return (
        <Button
            disabled={disabled}
            icon={icon}
            style={style}
            loading={loading}
            onClick={onClick}
            className={[styles["button"], type && styles[type]]}
            size="large"
        >
            {title}
        </Button>
    );
}
