import { Modal } from "antd";
import Image from "next/image";

import { useTranslation } from "react-i18next";

import IMAGES from "../configs/images";

import ButtonComponent from "./ButtonComponent";

export default function ErrorModal({ visible, onClose, text }) {
const { t } = useTranslation();

    return (
        <Modal
            width={700}
            open={visible}
            title={null}
            footer={null}
            centered
            closable={false}
            bodyStyle={{
                padding: "50px",
                textAlign: "center",
            }}
        >
            <div className="error-modal" style={{ borderRadius: "10px !important" }}>
                <h2>{t("SORRY_SOMETHING_WENT_WRONG")}</h2>

                <Image src={IMAGES.ERROR} height={200} width={200} alt="error" />

                <p style={{ marginTop: 20 }}>{text}</p>

                <div style={{ marginTop: 20, width: 200 }}>
                    <ButtonComponent type="error" title={t("close")} onClick={onClose} />
                </div>
            </div>
        </Modal>
    );
}
