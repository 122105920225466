import React from "react";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import { Button } from "antd";

import { useTranslation } from "react-i18next";

import PATHS from "../../../configs/paths";
import ICONS from "../../../configs/icons";
import IMAGES from "../../../configs/images";

import styles from "./pricingCard.module.scss";
import Image from "next/image";

export default function PricingCard({ data }) {
  const router = useRouter();

  const { t } = useTranslation();
  const { isLightMode } = useSelector((state) => state.appSlices);

  const onBuyNow = () => {
    router.push(PATHS.REGISTER);
  };

  let sortData = [];

  if (typeof data !== "string") {
    sortData = data?.sort((a, b) => a.credit - b.credit);
  }

  return sortData?.map((el) => (
    <div style={{ position: "relative" }} key={el?.id}>
      <div
        className={`${styles["pricing-card"]} ${
          el.is_popular && styles["effect_is_popular"]
        }`}
        onClick={onBuyNow}
        style={{
          background: isLightMode
            ? `linear-gradient(45deg, #fff, #00000012)`
            : "#173E4B",
          //   : `linear-gradient(45deg, #173E4B, #00000012)`,
          border: el.is_popular
            ? `1.3px solid #3CC82C`
            : isLightMode
            ? `1.3px solid transparent`
            : `1.3px solid #325d6b`,
        }}
      >
        {el.is_popular && (
          <div
            style={{ color: isLightMode ? "#fff" : "#121315" }}
            className={styles["trending"]}
          >
            {t("trending")}
          </div>
        )}
        <p
          className={styles["title"]}
          style={{ color: isLightMode ? "#1f2226" : "#fff" }}
        >
          {el?.name}
        </p>
        <div className={styles["price-wrap"]}>
          <div className={styles["valute"]}>
            $ <span className={styles["price"]}>{el?.price}</span>
          </div>

          <div
            className={styles["valute"]}
            style={{ color: isLightMode ? "#808080" : "#C9C9EB" }}
          >
            R$ <span className={styles["price"]}>{el?.brl_price}</span>
          </div>
          <p
            className={styles["credit"]}
            style={{ color: isLightMode ? "#808080" : "#C9C9EB" }}
          >
            {ICONS.CHECK} {t("credit")} {el?.credit}
          </p>
        </div>

        <Button className={styles["buy-now"]}>{t("buy_now")}</Button>
        <Image
          src={
            isLightMode
              ? IMAGES.PRICE_SUBTRACT.src
              : IMAGES.PRICE_SUBTRACT_DARK.src
          }
          alt="become a partner"
          width={73}
          height={73}
        />
      </div>
    </div>
  ));
}
