import Image from "next/image";
import classNames from "../styles/feature.module.scss";

/**
 * @param {string} title                Possibility title
 * @param {string} description          Possibility description
 *
 * @returns Possibility component
 */

export default function Feature({ icon, image, title, description, style }) {
    return (
        <div className={classNames.feature} style={style}>
            <div className={classNames.icon_block}>
                {icon ? icon : <Image src={image} alt={title} />}
            </div>
            <div className={classNames.info_block}>
                <p className={classNames.title}>{title}</p>
                <p>{description}</p>
            </div>
        </div>
    );
}
