import Head from "next/head";
import { useRouter } from "next/router";

import { useState, useEffect } from "react";

import Home from "./lending-page/home";
import Features from "./lending-page/features";
import Platforms from "./lending-page/platforms";
import DownloadApp from "./lending-page/download-app";
import LatestNews from "./lending-page/latest-news";
import Pricing from "./lending-page/pricing";
import ContactUs from "./lending-page/contact-us";
import ResellerPanel from "./lending-page/reseller-panel";
import Branding from "./lending-page/branding";
import ServerDns from "./lending-page/server-dns";
import BrandedApp from "./lending-page/branded-app";
import AppFeatures from "./lending-page/app-features";
import { scrollBottom } from "../configs";
import URL from "../configs/url";
import TITLES from "../configs/titles";
import { APP_NAME } from "../configs/config";

export default ({ platforms, news, packages, ...props }) => {
  const router = useRouter();
  const [windowWidth, setWindowWidth] = useState(1200);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (router.asPath == "/#contact-us") {
      scrollBottom();
    }
  }, [router]);

  const onChangeInView = async (inView, name) => {
    if (windowWidth < 768) {
      return;
    }

    if (inView) {
      props.onChangeActive(name);
    }
  };

  const defaultPath = async () => {
    router
      .replace(
        {
          pathname: window.location.pathname,
          hash: "home",
        },
        null,
        {
          shallow: true,
        }
      )
      .catch((e) => {
        if (!e.cancelled) {
          throw e;
        }
      });
  };

  useEffect(() => {
    if (router?.asPath === "/") {
      defaultPath();
    }
  }, [router.asPath]);

  return (
    <>
      <Head>
        <title>
          {router?.asPath !== "/"
            ? (function () {
                switch (router.asPath) {
                  case "/#home":
                    return `${APP_NAME} / ${TITLES.HOME}`;

                  case "/#pricing":
                    return `${APP_NAME} / ${TITLES.PRICING}`;

                  case "/#platforms":
                    return `${APP_NAME} / ${TITLES.PLATFORMS}`;

                  case "/#reseller-panel":
                    return `${APP_NAME}/${TITLES.RESELLER_PANEL}`;

                  case "/#branding":
                    return `${APP_NAME}/${TITLES.BRANDING}`;

                  case "/#server-dns":
                    return `${APP_NAME}/${TITLES.SERVER_DNS}`;

                  case "/#branded-app":
                    return `${APP_NAME}/${TITLES.BRANDED_APP}`;

                  case "/#features":
                    return `${APP_NAME} / ${TITLES.FEATURES}`;

                  case "/#app-features":
                    return `${APP_NAME} / ${TITLES.APP_FEATURES}`;

                  case "/#news":
                    return `${APP_NAME} / ${TITLES.LATEST_NEWS}`;

                  case "/#contact-us":
                    return `${APP_NAME} / ${TITLES.CONTACT_US}`;

                  default:
                    break;
                }
              })()
            : APP_NAME}
        </title>

        <meta
          name="description"
          content="By XCloudTV you can watch your favourite movies and series. 100+ Resellers who are using our IPTV reseller solution and expand in IPTV/OTT Platform."
        />
      </Head>

      {/* <div> */}
      <Home onChangeInView={onChangeInView} />

      <Pricing packages={packages} onChangeInView={onChangeInView} />

      <Platforms platforms={platforms} onChangeInView={onChangeInView} />

      <DownloadApp onChangeInView={onChangeInView} />

      <ResellerPanel onChangeInView={onChangeInView} />

      <Branding onChangeInView={onChangeInView} />

      <ServerDns onChangeInView={onChangeInView} />

      <BrandedApp onChangeInView={onChangeInView} />

      <Features onChangeInView={onChangeInView} />

      <AppFeatures onChangeInView={onChangeInView} />

      <LatestNews news={news} onChangeInView={onChangeInView} />

      <ContactUs onChangeInView={onChangeInView} />
      {/* </div> */}
    </>
  );
};

export const getStaticProps = async () => {
  try {
    const platformsRes = await fetch(
      URL.PLATFORMS +
        "?" +
        new URLSearchParams({
          query: JSON.stringify({
            sort: ["is_released", "DESC"],
          }),
        })
    );
    const platforms = await platformsRes.json();

    const newsRes = await fetch(
      URL.NEWS +
        "?" +
        new URLSearchParams({
          query: JSON.stringify({
            limit: 3,
            sort: ["createdAt", "DESC"],
          }),
        })
    );
    const news = await newsRes.json();
    const packagesRes = await fetch(URL.PACKAGES);
    const packages = await packagesRes.json();

    return {
      props: {
        platforms,
        news,
        packages,
      },
    };
  } catch (err) {
    return {
      props: {
        platforms: [],
        news: [],
        packages: [],
      },
    };
  }
};
