import { useRouter } from "next/router";

import { InView } from "react-intersection-observer";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

// import IMAGES from "../../../configs/images";
import PATHS from "../../../configs/paths";

import News from "../../../components/News";
import Title from "../../../components/Title";

import styles from "./index.module.scss";

export default function LatestNews({ news, onChangeInView }) {
  const router = useRouter();
  const { isLightMode } = useSelector((state) => state.appSlices);

  const { t } = useTranslation();

  return news?.rows?.length ? (
    <InView
      as="div"
      threshold={0.5}
      onChange={(inView) => onChangeInView(inView, PATHS.LATEST_NEWS)}
    >
      <section id="news">
        <div className={styles["latest-news"]}>
          <div className={styles["container"]}>
            <Title style={{ color: isLightMode ? "#000" : "#fff" }}>
              {t("latest_news")}
            </Title>

            <div className={styles["list"]}>
              {news?.rows?.map((item) => {
                return <News key={item.id} item={item} />;
              })}
            </div>

            <div
              className={styles["view-more"]}
              onClick={() => router.push(PATHS.NEWS)}
            >
              {t("view_more")}
            </div>
          </div>
        </div>
      </section>
    </InView>
  ) : null;
}
