import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import IMAGES from "../configs/images";

SwiperCore.use([Autoplay]);

export default function PlatformsList({ children }) {
  const { isLightMode } = useSelector((state) => state.appSlices);

  const [windowWidth, setWindowWidth] = useState(1200);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div style={{ position: "relative", width: "100%" }}>
      <div
        className="shadow_left"
        style={{
          backgroundImage: isLightMode
            ? "linear-gradient(to right, rgb(248 249 250) 0%, rgba(255, 255, 255, 0) 100%)"
            : `url(${IMAGES.LEFT_GRADIENT.src})`,
          width: isLightMode ? "200px" : "100%",
        }}
      />

      <Swiper
        autoplay={{ delay: 0 }}
        slidesPerView={3 || 5}
        spaceBetween={30}
        loop={true}
        speed={3000}
        className="centerItems"
        style={{
          width: windowWidth - 60,
        }}
        breakpoints={{
          420: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 30,
          },

          1400: {
            slidesPerView: 5,
            spaceBetween: 30,
          },
        }}
      >
        {children?.rows?.map((item) => {
          return (
            <SwiperSlide key={item.id}>
              <img
                src={item.icon || IMAGES.ERROR_IMG.src}
                onError={(e) => {
                  e.target.src = IMAGES.ERROR_IMG.src;
                }}
                alt={item.name}
                width={75}
              />

              {item.is_released && (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24ZM17.5605 10.0605L11.556 16.0605C11.4167 16.2 11.2513 16.3106 11.0692 16.3861C10.8871 16.4616 10.6919 16.5004 10.4948 16.5004C10.2976 16.5004 10.1024 16.4616 9.92034 16.3861C9.73824 16.3106 9.57281 16.2 9.4335 16.0605L6.438 13.0605C6.15674 12.7788 5.99888 12.397 5.99917 11.9989C5.99945 11.6009 6.15784 11.2193 6.4395 10.938C6.72116 10.6567 7.10301 10.4989 7.50106 10.4992C7.89911 10.4994 8.28074 10.6578 8.562 10.9395L10.497 12.879L15.4395 7.9395C15.7224 7.66626 16.1013 7.51507 16.4946 7.51849C16.8879 7.52191 17.2641 7.67966 17.5422 7.95777C17.8203 8.23588 17.9781 8.6121 17.9815 9.0054C17.9849 9.39869 17.8337 9.7776 17.5605 10.0605Z"
                    fill="#91F279"
                  />
                </svg>
              )}
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div
        className="shadow_right"
        style={{
          backgroundImage: isLightMode
            ? "linear-gradient(to right, rgb(248 249 250) 0%, rgba(255, 255, 255, 0) 100%)"
            : `url(${IMAGES.LEFT_GRADIENT.src})`,
          width: isLightMode ? "200px" : "100%",
        }}
      />
    </div>
  );
}
