import Image from "next/image";

import { InView } from "react-intersection-observer";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import PATHS from "../../../configs/paths";
import IMAGES from "../../../configs/images";

import Title from "../../../components/Title";

import style from "./index.module.scss";
import Link from "next/link";

export default function ResellerPanel({ onChangeInView }) {
    const { t } = useTranslation();
    const { isLightMode } = useSelector((state) => state.appSlices);

    return (
        <InView
            as="div"
            threshold={0.5}
            onChange={(inView) => onChangeInView(inView, PATHS.RESELLER_PANEL)}
        >
            <section id="resellerPanel">
                <div className={`${style["wrapper"]}`}>
                    <div className={style["container"]}>
                        <Title style={{ paddingBottom: "30px" }}>{t("reseller_panel")}</Title>
                        <div className={style["blocks"]}>
                            <article>
                                <div className={style["text-wrap"]}>
                                    <div
                                        className={`${style["start-text"]} ${
                                            !isLightMode ? style["start-text-light"] : ""
                                        }`}
                                    >
                                        <p>
                                            {t("reseller_panel_desc_1")}
                                            <strong> {t("reseller_panel_desc_2")}</strong>
                                            {t("reseller_panel_desc_3")}
                                        </p>
                                    </div>

                                    <div className={style["list-and-image-container"]}>
                                        <div className={style["list-container"]}>
                                            <div className={style["list-item"]}>
                                                <Image
                                                    src={IMAGES.CHECK}
                                                    className={style["list-item-image"]}
                                                />
                                                <div className={style["list-item-text"]}>
                                                    <strong>{t("reseller_panel_li_tit_1")}</strong>{" "}
                                                    <p>{t("reseller_panel_li_1")}</p>
                                                </div>
                                            </div>
                                            <div className={style["list-item"]}>
                                                <Image
                                                    src={IMAGES.CHECK}
                                                    className={style["list-item-image"]}
                                                />
                                                <div className={style["list-item-text"]}>
                                                    <strong>{t("reseller_panel_li_tit_3")}</strong>{" "}
                                                    <p>{t("reseller_panel_li_3")}</p>
                                                </div>
                                            </div>

                                            <div className={style["list-item"]}>
                                                <Image
                                                    src={IMAGES.CHECK}
                                                    className={style["list-item-image"]}
                                                />
                                                <div className={style["list-item-text"]}>
                                                    <strong>{t("reseller_panel_li_tit_5")}</strong>{" "}
                                                    <p>{t("reseller_panel_li_5")}</p>
                                                </div>
                                            </div>
                                            <div className={style["list-item"]}>
                                                <Image
                                                    src={IMAGES.CHECK}
                                                    className={style["list-item-image"]}
                                                />
                                                <div className={style["list-item-text"]}>
                                                    <strong>{t("reseller_panel_li_tit_2")}</strong>{" "}
                                                    <p>{t("reseller_panel_li_2")}</p>
                                                </div>
                                            </div>
                                            <div className={style["list-item"]}>
                                                <Image
                                                    src={IMAGES.CHECK}
                                                    className={style["list-item-image"]}
                                                />
                                                <div className={style["list-item-text"]}>
                                                    <strong>{t("reseller_panel_li_tit_4")}</strong>{" "}
                                                    <p> {t("reseller_panel_li_4")}</p>
                                                </div>
                                            </div>
                                            <div className={style["list-item"]}>
                                                <Image
                                                    src={IMAGES.CHECK}
                                                    className={style["list-item-image"]}
                                                />
                                                <div className={style["list-item-text"]}>
                                                    <strong>{t("reseller_panel_li_tit_6")}</strong>{" "}
                                                    <p>{t("reseller_panel_li_6")}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={style["image-container"]}>
                                            <Image
                                                src={
                                                    isLightMode
                                                        ? IMAGES.RESELLER_PANEL_LIGHT
                                                        : IMAGES.RESELLER_PANEL
                                                }
                                                className={style["general-image"]}
                                                alt="reseller-panel"
                                            />
                                        </div>
                                    </div>
                                    <div className={style["call-to-action-container"]}>
                                        <div>
                                            <h3>{t("ready_to_take_your_app_to_the_next_level")}</h3>
                                            <p>{t("its_time_to_rock")} </p>
                                        </div>
                                        <Link href="/register">{t("get_started")}</Link>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </section>
        </InView>
    );
}
