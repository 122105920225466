import { InView } from "react-intersection-observer";
import { useTranslation } from "react-i18next";

import PATHS from "../../../configs/paths";

import Title from "../../../components/Title";

import PlatformsList from "../../../components/PlatformsList";

import styles from "./style.module.scss";

export default function Platforms({ platforms, onChangeInView }) {
  const { t } = useTranslation();

  return (
    <InView
      as="div"
      threshold={0.3}
      onChange={(inView) => onChangeInView(inView, PATHS.PLATFORMS)}
    >
      <section id="platforms">
        <div className={styles["page"]}>
          <div className={styles["container"]}>
            <div className="title-subtitle">
              <Title>{t("platforms_h2")}</Title>
              <p className="subtitle">{t("platforms_title")}</p>
            </div>
            <div className={styles["infinite-slider-wrap"]}>
              <PlatformsList children={platforms} />
            </div>
          </div>
        </div>
      </section>
    </InView>
  );
}
