import Image from "next/image";
import { InView } from "react-intersection-observer";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useState } from "react";

import { Button } from "antd";

import PATHS from "../../../configs/paths";
import IMAGES from "../../../configs/images";

import Title from "../../../components/Title";

import ContactUsModal from "./ContactUsModal";

import style from "./index.module.scss";

export default function BrandedApp({ onChangeInView }) {
  const { t } = useTranslation();
  const { isLightMode } = useSelector((state) => state.appSlices);

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <InView
      as="div"
      threshold={0.5}
      onChange={(inView) => onChangeInView(inView, PATHS.BRANDED_APP)}
    >
      <section>
        <div className={style["page"]}>
          <div
            className={style["wrapper"]}
            id="branded-app"
          > 
          <Image
            alt="branded app"
            src={ isLightMode
              ? IMAGES.BG_BRANDED_APP_LIGHT.src
              : IMAGES.BG_BRANDED_APP.src}
            quality={100}
            fill
            objectFit="cover" 
            priority={true} 
            sizes="100vw" 
          />

          <div className={style["container"]}>
            <Title>{t("get_your_own_branded_app")}</Title>
            <div className={style["blocks"]}> 
              <article className={`${ isLightMode ? style["transparent-bg"] : ""} text-wrap`}>
                <p>
                {t("get_your_own_branded_app_title")}   
                </p>
                <p><strong> {t("our_services_include")}:</strong></p> 
                <ul>
                  <li>{t("designing_the_app")}</li>
                  <li>{t("end_to_end_development")} </li>
                  <li>{t("publishing_it_to_the_app_stores")}</li>
                  <li>{t("providing_ongoing_support_after_release_on_stores")}</li>
                </ul>
                <p>{t("start_your_business_with_us")}</p>
                <Button className="button-scale" onClick={()=> setIsModalOpen(true)}>{t("contact_us")}</Button>
              </article>   
            </div>
          </div>
          </div>
        </div>
      <ContactUsModal
        open={isModalOpen}
        onclose={() => setIsModalOpen(false)}
      />
      </section>

    </InView>
  );
}
