import Image from "next/image";

import { InView } from "react-intersection-observer";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import PATHS from "../../../configs/paths";
import IMAGES from "../../../configs/images";

import Title from "../../../components/Title";

import style from "./index.module.scss";

export default function Branding({ onChangeInView }) {
  const { t } = useTranslation();
  const { isLightMode } = useSelector((state) => state.appSlices);

  return (
    <InView
      as="div"
      threshold={0.5}
      onChange={(inView) => onChangeInView(inView, PATHS.BRANDING)}
    >
      <section id="branding">
        <div className={`${style["wrapper"]}`}>
          <div className={style["container"]}>
            <Title>{t("branding_reseller")}</Title>
            <div className={style["blocks"]}>
              <Image
                src={isLightMode ? IMAGES.BRANDING_LIGHT : IMAGES.BRANDING}
                alt="Branding"
              />

              <article>
                <p className="text-wrap">{t("branding_desc")}</p>
              </article>
            </div>
          </div>
        </div>
      </section>
    </InView>
  );
}
