const TITLES = {
  // a
  ALL_NEWS: "All News page",
  APP_FEATURES: "App Features",
  ABOUT_US: "About Us",
  PLATFORMS: "Platforms",
  // b
  BECOME_A_PARTNER: "Become a Partner",
  BRANDING: "Branding",
  BRANDED_APP: "Get Your Own Branded App",
  // c
  CURRENT_NEWS: "Current News page",
  CONTACT_US: "Contact Us",
  // d
  DEVICE_ACTIVATION: "Device activation page",
  DOWNLOAD_APP: "Download APP",
  // e
  // f
  FEATURES: "Features",
  // g
  // h
  HOME: "IPTV reseller",
  // i
  INSTALLATION_GUIDE: "Installation Guide page",
  // j
  // k
  // l
  LATEST_NEWS: "Latest News",
  // m
  // n
  // o
  // p
  PRICING: "Pricing",
  PRIVACY_POLICY: "Privacy Policy page",
  // q
  // r
  REFOUND: "Refound terms",
  REGISTER: "Register page",
  RESELLER_PANEL: "Reseller panel",

  // s
  SERVER_DNS: "DNS Server",
  // t
  TERMS: "Terms of Usage",
  // u
  // v
  // w
  // x
  // y
  // z
};

export default TITLES;
