import Image from "next/image";

import { InView } from "react-intersection-observer";
import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";

import PATHS from "../../../configs/paths";
import IMAGES from "../../../configs/images";

import Title from "../../../components/Title";

import style from "./index.module.scss";

export default function ServerDns({ onChangeInView }) {
  const { t } = useTranslation();
  const { isLightMode } = useSelector((state) => state.appSlices);

  return (
    <InView
      as="div"
      threshold={0.5}
      onChange={(inView) => onChangeInView(inView, PATHS.SERVER_DNS)}
    >
      <section id="serverDns">
        <div className={`${style["wrapper"]}`}>
          <div className={style["container"]}>
            <Title>{t("dns_server_reseller")}</Title>
            <div className={style["blocks"]}>
              <article>
                <p className="text-wrap">{t("DNS_Server_desc")}</p>
              </article>
              <Image
                src={isLightMode ? IMAGES.SERVER_DNS_LIGHT : IMAGES.SERVER_DNS}
                alt="dns-server"
              />
            </div>
          </div>
        </div>
      </section>
    </InView>
  );
}
