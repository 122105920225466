import { InView } from "react-intersection-observer";
import Image from "next/image";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import PATHS from "../../../configs/paths";
import IMAGES from "../../../configs/images";
import {
  ANDROID_TV,
  ANDROID_TV_APK,
  ANDROID_MOBILE,
  IOS,
  ROKU,
  SAMSUNG_STORE,
  LG_STORE,
  ANDROID_MOBILE_APK,
  SAMSUNG_TV_FILE,
} from "../../../configs/config";

import Title from "../../../components/Title";
import style from "./index.module.scss";

export default function DownloadApp({ onChangeInView }) {
  const { t } = useTranslation();
  const { isLightMode } = useSelector((state) => state.appSlices);

  const onSamsungStore = () => {
    window.open(SAMSUNG_STORE);
  };
  const onAppStore = () => {
    window.open(IOS);
  };
  const onAndroidMobile = () => {
    window.open(ANDROID_MOBILE);
  };
  const onRoku = () => {
    window.open(ROKU);
  };
  const onAndroidTVApp = () => {
    window.open(ANDROID_TV);
  };
  const onAndroidTVAPK = async () => {
    try {
      const url = ANDROID_TV_APK;

      const link = document.createElement("a");

      link.href = url;

      link.download = "x-cloud.apk";

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    } catch (error) {
      console.error("Error while downloading APK:", error);
    }
  };
  const onAndroidTVMobileAPK = async () => {
    try {
      const url = ANDROID_MOBILE_APK;

      const link = document.createElement("a");

      link.href = url;

      link.download = "x-cloud/mobile.apk";

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    } catch (error) {
      console.error("Error while downloading APK:", error);
    }
  };
  const onLGStore = () => {
    window.open(LG_STORE);
  };

  const onSamsungTvFile = async () => {
    try {
      const url = SAMSUNG_TV_FILE;

      const link = document.createElement("a");

      link.href = url;

      link.download = "userwidget.zip";

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    } catch (error) {
      console.error("Error while downloading file:", error);
    }
  };

  const download_files = [
    {
      id: 1,
      icon: IMAGES.ANDROID_TV_APK,
      title: t("download_apk"),
      name: "Android TV",
      onClick: onAndroidTVAPK,
    },
    {
      id: 2,
      icon: IMAGES.SAMSUNG_TV,
      title: t("download_file"),
      name: "Samsung TV",
      onClick: onSamsungTvFile,
    },
    {
      id: 3,
      icon: IMAGES.ANDROID_MOBILE_APK,
      title: t("download_apk"),
      name: "Android Mobile",
      onClick: onAndroidTVMobileAPK,
    },
  ];
  const download_apps = [
    {
      id: 1,
      icon: IMAGES.SAMSUNG_STORE,
      title: t("available_on"),
      name: "Samsung Store",
      onClick: onSamsungStore,
    },
    {
      id: 2,
      icon: isLightMode ? IMAGES.APPLE : IMAGES.APPLE_DARK,
      title: t("download_on_the"),
      name: "App Store",
      onClick: onAppStore,
    },
    {
      id: 3,
      icon: IMAGES.LG,
      title: t("available_on"),
      name: "LG Store",
      onClick: onLGStore,
    },
    {
      id: 4,
      icon: IMAGES.ROKU,
      title: t("available_on"),
      name: "Roku",
      onClick: onRoku,
    },

    // {
    //   id: 3,
    //   icon: IMAGES.ANDROID_MOBILE,
    //   title: "Get it on",
    //   name: "Google Play",
    //   onClick: onAndroidMobile,
    // },
    // {
    //   id: 5,
    //   icon: IMAGES.ANDROID_TV,
    //   title: "Available on",
    //   name: "Android App",
    //   onClick: onAndroidTVApp,
    // },
  ];

  return (
    <InView
      as="div"
      threshold={0.4}
      onChange={(inView) => onChangeInView(inView, PATHS.DOWNLOAD)}
    >
      <section id="download">
        <div className={style["page"]}>
          <Title>{t("download")}</Title>

          <div className={style["container"]}>
            <div className={style["wrap"]}>
              <div className={style["left-section"]}>
                <div className={style["download-app"]}>
                  <p className={style["sub-title"]}>{t("download_app")}</p>
                  <div className={style["app-btn-list"]}>
                    {download_apps.map((el) => (
                      <div
                        className={style["app-btn"]}
                        onClick={el.onClick}
                        style={{
                          backgroundColor: isLightMode ? "#000" : "#fff",
                          color: isLightMode ? "#fff" : "#000",
                        }}
                        key={el.id}
                      >
                        <div className={style["app-btn-icon"]}>
                          <Image
                            src={el.icon}
                            alt={el.name}
                            width={45}
                            height={45}
                          />
                        </div>
                        <div>
                          <p>{el.title}</p>
                          <p>
                            <strong>{el.name}</strong>
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className={style["download-files"]}>
                  <p className={style["sub-title"]}> {t("download_files")} </p>
                  <p className={style["sub-title-desc"]}>
                    {t("want_to_upgrade_your_tariff")}
                  </p>
                  <div className={style["app-btn-list"]}>
                    {download_files.map((el) => (
                      <div
                        className={style["app-btn"]}
                        onClick={el.onClick}
                        style={{
                          backgroundColor: isLightMode ? "#000" : "#fff",
                          color: isLightMode ? "#fff" : "#000",
                        }}
                        key={el.id}
                      >
                        <div className={style["app-btn-icon"]}>
                          <Image
                            src={el.icon}
                            alt={el.name}
                            width={45}
                            height={45}
                          />
                        </div>
                        <div>
                          <p>{el.title}</p>
                          <p>
                            <strong>{el.name}</strong>
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className={style["download-img"]}>
                <Image
                  src={
                    isLightMode
                      ? IMAGES.DOWNLOADAPP_LIGHT
                      : IMAGES.DOWNLOADAPP_DARK
                  }
                  alt="download app"
                  width={750}
                  height={593}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </InView>
  );
}
